import { constants } from '../store';

class ImageLoader {
  constructor() {
    this.dom = {};
  }

  loadCritical(size, cb) {
    this.dom.criticalImages = document.querySelectorAll('[data-load="critical"]');

    const total = this.dom.criticalImages.length;

    if (total === 0) {
      constants.criticalImagesLoaded = true;

      cb && cb();

      return;
    }

    let count = 0;

    [...this.dom.criticalImages].forEach((image) => {
      this.load(image, size, () => {
        count += 1;

        if (count === total) {
          constants.criticalImagesLoaded = true;

          cb && cb();
        }
      });
    });
  }

  load = (el, size, cb) => {
    if (!el) return;

    const { alt } = el.dataset;
    let src = el.getAttribute(`data-src-${size}`);

    const img = new Image();
    img.classList.add('img', 'js-img');
    img.alt = alt;
    img.src = src;

    const appendChild = () => {
      el.insertBefore(img, el.firstChild);

      cb && cb();
    };

    if ('decode' in img) {
      img.decode()
        .then(() => {
        // el.style.opacity = 1;
          el.removeAttribute('data-load');
          el.removeAttribute('data-src-mobile');
          el.removeAttribute('data-src-tablet');
          el.removeAttribute('data-src-desktop');
          el.removeAttribute('data-alt');

          appendChild();
        })
        .catch(appendChild);
    } else {
      appendChild();
    }
  }
}

export default ImageLoader;
