import { TimelineMax } from 'gsap';

class Header {
  constructor() {
    this.header = document.querySelector('.js-header');

    this.DOM = {
      header: this.header,
      visual: this.header.querySelector('.js-image'),
      text: this.header.querySelector('.js-text'),
      contentEls: this.header.querySelectorAll('.js-content > *'),
      btnEls: this.header.querySelectorAll('.js-buttons > *'),
      yellow: this.header.querySelector('.js-cta')
    };

    this.tl = null;
  }

  init() {
    this.tl = new TimelineMax({ paused: true });

    if (this.DOM.visual) {
      this.tl
        .addLabel('start')

        .to(this.DOM.visual, { autoAlpha: 1, duration: 1 })
        .call(() => this.DOM.header.classList.add('header--overlay'), this, 'start+=1');
      this.tl.staggerTo(this.DOM.contentEls, 0.5, { autoAlpha: 1, y: 0 }, '0.1', 'start+=0.7');
      this.tl.staggerTo(this.DOM.btnEls, 0.5, { autoAlpha: 1, y: 0 }, '0.1', 'start+=0.7');
    }

    if (this.DOM.yellow) {
      this.tl.addLabel('start');
      this.tl.to(this.DOM.yellow, { autoAlpha: 1, duration: 1 });
      this.tl.staggerTo(this.DOM.contentEls, 0.5, { autoAlpha: 1, y: 0 }, '0.1', 'start');
      this.tl.staggerTo(this.DOM.btnEls, 0.5, { autoAlpha: 1, y: 0 }, '0.1', 'start');
    }

    if (this.DOM.text) {
      this.tl.addLabel('start');
      this.tl.staggerTo(this.DOM.contentEls, 0.5, { autoAlpha: 1, y: 0 }, '0.1', 'start');
      this.tl.staggerTo(this.DOM.btnEls, 0.5, { autoAlpha: 1, y: 0 }, '0.1', 'start');
    }

    this.tl.play();
  }
}

export default Header;
