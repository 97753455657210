import { onReady } from './renderers/global';

// components
import Header from './components/header/header';
import Nav from './components/nav/nav';
import SearchForm from './components/search-form/search-form';

const headerEl = document.querySelector('.js-header');
const navEl = document.querySelector('.js-nav');
const searchEl = document.querySelector('.js-search-form');

if (headerEl) {
  const header = new Header();
  header.init();
}

if (navEl) {
  const nav = new Nav();
  nav.init();
}

if (searchEl) {
  const search = new SearchForm();
  search.init();
}

// import styles
import './index.scss';

// import routing
// import './utils/routing';

// the location on the page is not restored, user will need to scroll manually
if ('scrollRestoration' in window.history) window.history.scrollRestoration = 'manual';

// fire onReady fn on DOMContentLoaded
onReady();
