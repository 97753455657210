import { constants } from '../store';

export const criticalImagesLoaded = (cb) => {
  const interval = setInterval(() => {
    if (constants.criticalImagesLoaded) {
      clearInterval(interval);

      cb();
    }
  });
};
