import { TweenMax } from 'gsap';

class Nav {
  constructor() {
    this.dom = {};
    this.dom.el = document.querySelector('.js-nav');
    this.dom.item = document.querySelectorAll('.js-nav-item');
    this.dom.toggleGroup = document.querySelectorAll('.js-toggle-group');
    this.dom.group = document.querySelectorAll('.js-group');
    this.dom.toggleSubmenu = document.querySelectorAll('.js-toggle-submenu');
    this.dom.submenu = document.querySelectorAll('.js-submenu');
    this.dom.navButton = document.querySelector('.js-nav-button');
    this.dom.nav = document.querySelector('.js-mobile');
  }

  toggleGroups = (i) => {
    // let oldHeight = this.dom.group[i].height;
    // let newHeight = oldHeight ? 0 : 'auto';

    this.dom.group[i].classList.toggle('is-open');
    this.dom.toggleGroup[i].classList.toggle('is-open');

    if (this.dom.group[i].classList.contains('is-open')) {
      // TweenMax.set(this.dom.group[i], { height: 'auto', marginTop: '5.56vw' });
      // TweenMax.from(this.dom.group[i], 0.5, { height: 0, marginTop: 0 });
      TweenMax.fromTo(this.dom.group[i], 0.5, { height: 0, marginTop: 0 }, { height: 'auto', marginTop: '5.56vw' });
    } else {
      // TweenMax.set(this.dom.group[i], { height: 0, marginTop: 0 });
      // TweenMax.from(this.dom.group[i], 0.5, { height: 'auto', marginTop: '5.56vw' });
      TweenMax.fromTo(this.dom.group[i], 0.5, { height: 'auto', marginTop: '5.56vw' }, { height: 0, marginTop: 0 });
    }

    // TweenMax.set(this.dom.group[i], { height: newHeight });
    // TweenMax.from(this.dom.group[i], 2, { height: oldHeight });
  }

  toggleSubmenus = (i) => {
    // let oldHeight = 'auto';
    // let newHeight = oldHeight ? 0 : 'auto';

    this.dom.submenu[i].classList.toggle('is-open');
    this.dom.toggleSubmenu[i].classList.toggle('is-open');

    if (this.dom.submenu[i].classList.contains('is-open')) {
      // TweenMax.set(this.dom.submenu[i], { height: 'auto', paddingBottom: '5.56vw' });
      // TweenMax.from(this.dom.submenu[i], 0.5, { height: 0, paddingBottom: 0 });
      TweenMax.fromTo(this.dom.submenu[i], 0.5, { height: 0, marginTop: 0 }, { height: 'auto', marginTop: '5.56vw' });
    } else {
      // TweenMax.set(this.dom.submenu[i], { height: 0, paddingBottom: 0 });
      // TweenMax.from(this.dom.submenu[i], 0.5, { height: 'auto', paddingBottom: '5.56vw' });
      TweenMax.fromTo(this.dom.submenu[i], 0.5, { height: 'auto', marginTop: '5.56vw' }, { height: 0, marginTop: 0 });
    }
  }

  toggleNav = () => {
    this.dom.nav.classList.toggle('is-open');
    this.dom.el.classList.toggle('is-open');
  }

  init() {
    [...this.dom.toggleGroup].forEach((button, i) => { button.addEventListener('click', () => this.toggleGroups(i)); });
    [...this.dom.toggleSubmenu].forEach((button, i) => { button.addEventListener('click', () => this.toggleSubmenus(i)); });
    this.dom.navButton.addEventListener('click', this.toggleNav);
  }
}

export default Nav;
