const observerSettings = {
  rootMargin: '0px 0px 0px 0px',
  threshold: [0, 0]
};

class Observer {
  constructor() {
    this.dom = {
      els: document.querySelectorAll('.js-observe')
    };

    if (this.dom.els.length < 1) return;

    this.settings = observerSettings;
    this.observer = null;

    this.rAF = null;
  }

  createObserver() {
    this.observer = new IntersectionObserver(this.handleIntersect, this.settings);
  }

  handleIntersect = (entries) => {
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        entry.target.classList.add('is-intersected');

        // this.observer.unobserve(entry.target);
      } else {
        entry.target.classList.remove('is-intersected');
      }
    });
  }

  observe = () => {
    if (!this.observer) return;

    this.dom.els.forEach(el => this.observer.observe(el));
    window.requestAnimationFrame(this.observe);
  }

  init() {
    this.createObserver();
    this.rAF = window.requestAnimationFrame(this.observe);
  }

  destroy() {
    this.observer.disconnect();
    this.observer = null;

    window.cancelAnimationFrame(this.rAF);
  }
}

export default Observer;
