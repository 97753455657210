import Filter from './filter';
// import Cursor from './cursor';

class Overview {
  constructor(index) {
    this.overview = document.querySelectorAll('.js-overview')[index];

    this.DOM = {
      overview: this.overview,
      container: this.overview.querySelector('.js-container'),
      items: this.overview.querySelectorAll('.js-item'),
      filterEl: this.overview.querySelector('.js-filter'),
      cursor: this.overview.querySelector('.js-cursor')
    };
  }

  init() {
    if (this.DOM.filterEl) {
      this.DOM.filterItems = this.overview.querySelectorAll('.js-item');

      this.filter = new Filter(this.DOM.overview, this.DOM.container, this.DOM.items);
      this.filter.init();
    }

    // if (this.DOM.cursor) {
    //   this.cursor = new Cursor(this.DOM.overview, this.DOM.container, this.DOM.items);
    //   this.cursor.init();
    // }
  }
}

export default Overview;
