import { constants } from '../../store';

class SearchForm {
  constructor() {
    this.dom = {};
    this.dom.el = document.querySelector('.js-search-form');
    this.dom.button = this.dom.el.querySelector('.js-search-button');
    this.dom.input = this.dom.el.querySelector('.js-input');
    this.dom.autoSuggest = this.dom.el.querySelector('.js-as');
    this.dom.close = this.dom.el.querySelector('.js-close');

    this.dom.nav = document.querySelector('.js-nav-menu');

    this.state = {
      url: this.dom.el.getAttribute('data-url'),
      isActive: false
    };
  }

  toggleSearch = (e) => {
    e.preventDefault();

    this.dom.el.classList.toggle('is-active');
    this.dom.input.focus();

    if (this.dom.el.classList.contains('is-active')) {
      this.dom.nav.classList.add('is-hidden');
    } else {
      this.dom.nav.classList.remove('is-hidden');
      !this.dom.autoSuggest.classList.contains('is-hidden') && this.dom.autoSuggest.classList.add('is-hidden');
    }
  }

  inputHandler = (e) => {
    const keyword = e.target.value;

    const data = new FormData();

    data.append('action', 'data_fetch');
    data.append('s', keyword);

    if (keyword.length < 3) {
      // hide auto suggest
      !this.dom.autoSuggest.classList.contains('is-hidden') && this.dom.autoSuggest.classList.add('is-hidden');

      this.state.isActive = false;

      // clear list
      this.dom.autoSuggest.innerHTML = '';

      return;
    }

    this.state.isActive = true;

    // fetch data
    fetch(this.state.url, {
      method: 'POST',
      body: data
    })
      .then(response => response.json())
      .then((results) => {
        // if (results.success === false || results.data.length < 1) return;
        if (!results.success || results.data.length < 1) {
          // hide auto suggest
          !this.dom.autoSuggest.classList.contains('is-hidden') && this.dom.autoSuggest.classList.add('is-hidden');

          // clear list
          this.dom.autoSuggest.innerHTML = '';

          return;
        }

        // clear list
        this.dom.autoSuggest.innerHTML = '';

        // show auto suggest
        this.dom.autoSuggest.classList.contains('is-hidden') && this.dom.autoSuggest.classList.remove('is-hidden');

        [...results.data].forEach((result) => {
          // create list items w/ links
          const listItem = document.createElement('li');
          const link = document.createElement('a');

          listItem.classList.add('search-form__as-item');
          link.classList.add('search-form__as-link');

          let title = result.title;
          if (result.title.toLowerCase().includes(keyword.toLowerCase())) {
            const index = title.toLowerCase().indexOf(keyword.toLowerCase());

            // highlighted text
            if (index >= 0) {
              title = `
                ${title.substring(0, index)}<span>${title.substring(index, index + keyword.length)}</span>${title.substring(index + keyword.length)}
              `;
            }
          }

          link.href = result.url;
          link.innerHTML = title;

          listItem.appendChild(link);
          this.dom.autoSuggest.appendChild(listItem);
        });
      });
  }

  keydownHandler = (e) => {
    if (e.keyCode === 13) {
      if (this.state.isActive && !constants.isDevice) {
        this.dom.el.submit();
      }
    }
  }

  addEventListener() {
    document.body.addEventListener('keydown', this.keydownHandler);

    this.dom.button.addEventListener('click', this.toggleSearch);
    this.dom.close.addEventListener('click', this.toggleSearch);

    this.dom.input.addEventListener('input', this.inputHandler);
  }

  removeEventListener() {
    this.dom.button.removeEventListener('click', this.toggleSearch);
    this.dom.close.removeEventListener('click', this.toggleSearch);
  }

  init() {
    if (constants.isDevice) {
      this.dom.el.classList.add('is-mobile');
    }

    this.addEventListener();
  }

  destroy() {
    this.removeEventListener();
  }
}

export default SearchForm;
