import { lerp } from './math';
import { constants } from '../store';

class Scroll {
  constructor() {
    this.dom = {
      main: document.querySelector('main'),
      scrollContainer: document.querySelector('.page'),
      sections: document.querySelectorAll('[data-scroll]'),
    };

    this.state = {
      ease: 0.1,
      current: 0,
      previous: 0,
      rounded: 0,
      ticker: 0
    };

    this.scrollContainers = this.dom.sections;
  }

  setBodyHeight = () => {
    if (this.dom.scrollContainer) { document.body.style.height = `${this.dom.scrollContainer.getBoundingClientRect().height}px`; }
  };

  render = () => {
    this.startTicker();

    if (this.dom.scrollContainer) {
      this.state.current = window.scrollY;
      this.state.previous = lerp(this.state.previous, this.state.current, this.state.ease);
      this.state.rounded = Math.round(this.state.previous * 100) / 100;

      this.dom.scrollContainer.style.transform = `translateY(-${this.state.rounded}px)`;
    }
  };

  startTicker = () => {
    this.state.ticker = window.requestAnimationFrame(this.render);
  };

  removeTicker = () => {
    window.cancelAnimationFrame(this.state.ticker);
  };

  setCache(event = '') {
    if (event === 'resize') {
      this.setBodyHeight();
    } else {
      this.setBodyHeight();
    }
  }

  // inViewport(index) {
  //   if (!this.sections) return;

  //   const cache = this.sections[index];
  //   const transform = Math.abs(this.state.last);

  //   const { top, height } = cache.bounds;
  //   const inView = transform >= (top - 200) - window.innerHeight && transform < top + height;

  //   if (inView) {
  //     cache.el.style.transform = `translate3d(0, ${transform * -1}px, 0)`;
  //     cache.inView = true;
  //     cache.el.style.visibility = 'visible';
  //   } else {
  //     const resetTop = -top - height;
  //     cache.el.style.transform = `translate3d(0, ${resetTop}px, 0)`;
  //     cache.inView = false;
  //     cache.el.style.visibility = 'hidden';
  //   }
  // }

  handleResize = () => {
    // [...this.dom.sections].forEach((section) => section.style.transform = 'translate3d(0, 0, 0)');

    this.setCache('resize');
    // this.disable();
    // this.enable();
  }

  events() {
    window.addEventListener('resize', this.handleResize, { passive: true });
  }

  enable() {
    this.startTicker();
  }

  disable() {
    this.removeTicker();
  }

  init() {
    const interval = setInterval(() => {
      if (constants.pageEntranceFinished) {
        clearInterval(interval);
        if (!constants.isDevice) {
          this.events();
          this.setCache();
        } else {
          constants.sectionsInView = this.dom.sections;
        }

        this.startTicker();
      }
    });
  }

  destroy() {
    // gsap.ticker.remove(this.render);
    this.removeTicker();

    // this.vs && this.vs.destroy();

    window.removeEventListener('resize', this.handleResize, { passive: true });
  }
}

export default Scroll;
