class VideoBanner {
  constructor({ el, index }) {
    this.dom = {};
    this.dom.el = el;
    this.dom.playButton = this.dom.el.querySelector('.js-play');
    this.dom.url = this.dom.playButton.dataset.url;
    this.dom.closeButton = null;
    this.dom.allVideoContainers = document.querySelectorAll('.js-video-container');

    this.state = {
      video: null,
      component: {
        index
      }
    };
  }

  createVideo = () => {
    if (this.state.video === null) {
      this.state.video = document.createElement('video');
      this.state.video.style.width = '0';
      this.state.video.style.height = '0';
      this.state.video.src = this.dom.url;

      // append video to the body and make it full screen
      document.body.appendChild(this.state.video);
    }

    // play the video
    // Mag ook een array zijn met alle prefixes
    if (this.state.video.requestFullscreen) {
      this.state.video.requestFullscreen();
      // @ts-ignore
    } else if (this.state.video.mozRequestFullscreen) {
      // @ts-ignore
      this.state.video.mozRequestFullscreen();
      // @ts-ignore
    } else if (this.state.video.webkitRequestFullscreen) {
      // @ts-ignore
      this.state.video.webkitRequestFullscreen();
      // @ts-ignore
    } else if (this.state.video.webkitEnterFullScreen) {
      // @ts-ignore
      this.state.video.webkitEnterFullScreen();
      // @ts-ignore
    } else if (this.state.video.msRequestFullscreen) {
      // @ts-ignore
      this.state.video.msRequestFullscreen();
    }

    this.state.video.play();
  }

  handleCloseFullScreen = () => {
    if (!this.state.video) return;

    // @ts-ignore
    if (!document.webkitIsFullScreen && !document.mozFullScreen && !document.msFullscreenElement) {
      this.state.video.pause();
    }
  };

  addEventListeners = () => {
    this.dom.playButton.addEventListener('click', this.createVideo);

    const prefixes = ['', 'moz', 'webkit', 'ms'];

    prefixes.forEach((prefix) => {
      document.addEventListener(`${prefix}fullscreenchange`, this.handleCloseFullScreen);
    });
  }

  removeEvents = () => {
    this.dom.playButton.removeEventListener('click', this.createVideo);

    const prefixes = ['', 'moz', 'webkit', 'ms'];

    prefixes.forEach((prefix) => {
      document.removeEventListener(`${prefix}fullscreenchange`, this.handleCloseFullScreen);
    });
  }

  init = () => {
    this.addEventListeners();
  }

  destroy = () => {
    this.removeEvents();
  }
}

export default VideoBanner;
