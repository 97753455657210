import Time from '../utils/time';
import ImageLoader from '../utils/image-loader';
import { criticalImagesLoaded } from '../utils/critical-images-loaded';
import VideoBanner from '../components/video_banner/video_banner';
import Observer from '../utils/observer';
import initFadeEls from '../utils/fade';
import initMoveYEls from '../utils/move-y';
import Overview from '../components/overview/overview';
import initSelects from '../utils/init-selects';
import Scroll from '../utils/scroll';

import { constants, instances } from '../store';

let videoBannerEls;
let videoBanner = [];
let overviewEls;

const globalEvents = (namespace = null) => {
  constants.namespace = namespace;

  if (window.innerWidth < 480) constants.size = 'mobile';
  else if (window.innerWidth >= 480 && window.innerWidth < 768) constants.size = 'tablet';
  else constants.size = 'desktop';

  const vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', `${vh}px`);

  // load critical (above the fold) images first
  instances.imageLoader.loadCritical(constants.size, () => {
    const els = document.querySelectorAll('[data-load="lazy"]');

    [...els].forEach((el) => instances.imageLoader.load(el, constants.size));
  });

  criticalImagesLoaded(() => {
    instances.scroll = new Scroll();
    instances.scroll.init();

    overviewEls = document.querySelectorAll('.js-overview');
    if (overviewEls.length > 0) {
      initSelects();

      [...overviewEls].forEach((el, index) => {
        const overview = new Overview(index);
        overview.init();
      });
    }
    videoBannerEls = document.querySelectorAll('.js-video-banner');
    [...videoBannerEls].forEach((el, index) => {
      const clsVideoBanner = new VideoBanner({ el, index });
      videoBanner.push(clsVideoBanner);
      clsVideoBanner.init();
    });

    const observerEls = document.querySelectorAll('.js-observe');

    if (observerEls.length > 0) {
      setTimeout(() => {
        const observer = new Observer();
        observer.init();
      }, 10);
    }

    // criticalImagesLoaded(() => {
    //   instances.scroll = new Scroll();
    //   instances.scroll.init();

    //   overviewEls = document.querySelectorAll('.js-overview');
    //   if (overviewEls.length > 0) {
    //     initSelects();

    //     [...overviewEls].forEach((el, index) => {
    //       const overview = new Overview(index);
    //       overview.init();
    //     });
    //   }
    // });

    initFadeEls();
    initMoveYEls();
  });
};

const onResize = () => {
  if (window.innerWidth < 480) constants.size = 'mobile';
  else if (window.innerWidth >= 480 && window.innerWidth < 768) constants.size = 'tablet';
  else constants.size = 'desktop';

  const vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', `${vh}px`);
};

export const onReady = () => {
  const view = document.querySelector('[data-router-view]');
  const namespace = view.dataset.routerView;

  // detect mobile device and set body class
  constants.isDevice = 'ontouchstart' in window;
  constants.isDevice && document.body.classList.add('is-device');

  const header = document.querySelector('.js-header');
  if (header) constants.headerLayout = header && header.dataset.layout;

  instances.imageLoader = new ImageLoader();
  instances.time = new Time();

  globalEvents(namespace);

  window.addEventListener('resize', onResize);
};

export const onLeave = () => {
  if (!constants.isDevice && instances.scroll) {
    instances.scroll.destroy();
    instances.scroll = undefined;
  }
};

export const onEnter = () => {};

export const onEnterCompleted = ({ to }) => {
  globalEvents(to.view.dataset.routerView);
};
