import { TweenMax } from 'gsap';

class Filter {
  constructor(el, container, items) {
    this.overview = el;

    this.DOM = {
      overview: this.overview,
      container,
      items,
      filter: this.overview.querySelector('.js-filter'),
      filters: this.overview.querySelectorAll('.js-filter-type'),
      filterOptions: [],
      placeholders: [],
      resetButton: this.overview.querySelector('.js-reset')
    };

    this.state = {
      filters: [],
      placeholderValues: [],
      filteredItems: [],
      fallbackMessage: '<p class="overview__msg">Er zijn geen opleidingen gevonden met de gekozen filter criteria.</p>'
    };
  }

  setFilters() {
    const array = [];

    [...this.DOM.filters].forEach((filter) => {
      const object = {};
      object.type = filter.getAttribute('data-type');
      object.value = '';

      array.push(object);
    });

    this.state.filters = array;
  }

  setFilterOptions() {
    // wait a little bit till the options are loaded in the custom select (50ms)
    setTimeout(() => {
      this.DOM.filterOptions = this.DOM.filter.querySelectorAll('.select__option');
      this.DOM.placeholders = this.DOM.overview.querySelectorAll('.js-placeholder');

      [...this.DOM.filterOptions].forEach((option) => option.addEventListener('click', this.filter));
    }, 50);
  }

  setFilterValue(e) {
    const type = e.target.parentNode.parentNode.parentNode.getAttribute('data-type');
    const value = e.target.getAttribute('rel');

    [...this.state.filters].forEach((filter) => {
      if (filter.type === type) filter.value = value;
    });
  }

  filterItems() {
    TweenMax.to(this.DOM.resetButton, 0.2, { autoAlpha: 1 });

    this.state.filteredItems = [];

    [...this.DOM.items].forEach((item) => {
      let isValid = true;
      [...this.state.filters].forEach((filter) => {
        if (!isValid) return;
        if (!item.getAttribute(`data-${filter.type}`).includes(filter.value)) isValid = false;
        if (filter.value === '') isValid = true;
      });

      if (isValid) this.state.filteredItems.push(item);
    });
  }

  addItemsToContainer() {
    // clear container
    this.DOM.container.innerHTML = '';
    TweenMax.set(this.DOM.items, { autoAlpha: 0, y: 50 });

    if (this.state.filteredItems.length > 0) {
      [...this.state.filteredItems].forEach((item) => this.DOM.container.appendChild(item));
      TweenMax.staggerTo(this.state.filteredItems, 0.5, { autoAlpha: 1, y: 0 }, '0.05');
    } else {
      this.DOM.container.innerHTML = this.state.fallbackMessage;
    }
  }

  filter = (e) => {
    this.setFilterValue(e);
    this.filterItems();
    this.addItemsToContainer();
  }

  resetFilters = () => {
    this.state.filteredItems = [];

    // clear container
    this.DOM.container.innerHTML = '';

    // add all items to container
    [...this.DOM.items].forEach((item) => this.DOM.container.appendChild(item));
    TweenMax.staggerTo(this.DOM.items, 0.5, { autoAlpha: 1, y: 0 }, '0.05');

    // reset select placeholders
    [...this.DOM.placeholders].forEach((placeholder, index) => {
      placeholder.innerHTML = this.state.placeholderValues[index];
    });

    // hide reset filters button
    TweenMax.to(this.DOM.resetButton, 0.2, { autoAlpha: 0 });
  }

  setPlaceholderValues() {
    const placeholderValues = this.DOM.overview.querySelectorAll('.js-placeholder-value');

    [...placeholderValues].forEach((value) => this.state.placeholderValues.push(value.getAttribute('data-placeholder')));
  }

  init() {
    this.setFilters();
    this.setFilterOptions();
    this.setPlaceholderValues();

    this.DOM.resetButton.addEventListener('click', this.resetFilters);
  }
}

export default Filter;
