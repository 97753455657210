import { TweenMax, gsap } from 'gsap';

function animate(els) {
  [...els].forEach((obj) => {
    if (obj.el.classList.contains('is-intersected') && !obj.isAnimated) {
      obj.isAnimated = true;

      TweenMax.to(obj.el, 0.5, { autoAlpha: 1, y: 0 });
    }

    if (obj.el.classList.contains('is-intersected') && obj.isAnimated) return;

    if (!obj.el.classList.contains('is-intersected') && obj.isAnimated) {
      obj.isAnimated = false;

      TweenMax.set(obj.el, { autoAlpha: 0, y: 50 });
    }
  });
}

export default function initMoveYEls() {
  const els = document.querySelectorAll('.js-move-y');

  if (els.length < 1) return;

  [...els].forEach((el) => {
    const array = [];

    if (el.classList.contains('richtext')) {
      TweenMax.set(el, { autoAlpha: 1, y: 0 });

      [...el.children].forEach((child) => {
        const object = {};
        child.classList.add('js-move-y', 'js-observe');
        object.el = child;
        object.isAnimated = false;
        array.push(object);
      });
    } else {
      array.push({
        el,
        isAnimated: false
      });
    }

    gsap.ticker.add(() => {
      animate(array);
    });
  });
}
