export default function initSelects() {
  const selects = document.querySelectorAll('.js-select');

  if (selects.length < 1) return;

  [...selects].forEach((select) => {
    const options = select.querySelectorAll('option');
    const optionsCount = options.length;

    select.classList.add('select__hidden');

    const selectWrap = document.createElement('div');
    selectWrap.classList.add('select');

    const selectStyled = document.createElement('div');
    selectStyled.classList.add('select__styled', 'js-placeholder');
    selectStyled.innerHTML = options[0].innerHTML;

    select.parentNode.insertBefore(selectWrap, select);
    selectWrap.appendChild(select);
    selectWrap.appendChild(selectStyled);

    const list = document.createElement('ul');
    list.classList.add('select__options');

    for (let i = 0; i < optionsCount; i += 1) {
      const listItem = document.createElement('li');
      const option = options[i];

      listItem.classList.add('select__option');
      listItem.innerHTML = option.text;
      listItem.setAttribute('rel', option.value);
      list.appendChild(listItem);
    }

    selectWrap.appendChild(list);

    function toggleSelect(e) {
      e.stopPropagation();

      e.target.classList.toggle('is-open');
    }

    function handleChange(e) {
      e.stopPropagation();

      selectStyled.classList.remove('is-open');
      selectStyled.innerHTML = e.target.innerHTML;

      [...options].forEach((option, index) => {
        if (option.value === e.target.getAttribute('rel')) select.selectedIndex = `${index}`;
      });
    }

    const listItems = list.querySelectorAll('li');

    selectStyled.addEventListener('click', toggleSelect);
    [...listItems].forEach((li) => li.addEventListener('click', handleChange));
    document.body.addEventListener('click', () => {
      if (selectStyled.classList.contains('is-open')) selectStyled.classList.remove('is-open');
    });
  });
}
