import { TweenMax, gsap } from 'gsap';

function animate(els) {
  [...els].forEach((obj) => {
    if (obj.el.classList.contains('is-intersected') && !obj.isAnimated) {
      obj.isAnimated = true;

      TweenMax.to(obj.el, 1, { autoAlpha: 1 });
    }

    if (obj.el.classList.contains('is-intersected') && obj.isAnimated) return;

    if (!obj.el.classList.contains('is-intersected') && obj.isAnimated) {
      obj.isAnimated = false;

      TweenMax.set(obj.el, { autoAlpha: 0 });
    }
  });
}

export default function initFadeEls() {
  const els = document.querySelectorAll('.js-fade');

  if (els.length < 1) return;

  [...els].forEach((el) => {
    const array = [];

    array.push({ el, isAnimated: false });

    gsap.ticker.add(() => {
      animate(array);
    });
  });
}
